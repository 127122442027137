import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";

export function assertPassword(password) {
  return password.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})") !== null;
}

export function assertEmail(email) {
  return isEmail(email);
}

export function assertEmpty(value) {
  return isEmpty(value);
}
